import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import conf from 'configuration'
import { setStatus, setTimestamp } from 'features/error/errorSlice'
import { doLogout } from 'features/auth/authSlice'
import { getToken } from './auth'
import { getCompany } from './company'
import { COOLDOWN_RATE_LIMIT_IN_SECOND } from './constant/ratelimit'
import { getLocale } from './locale'

interface APIInstance extends AxiosInstance {
  CancelToken: typeof axios.CancelToken
  isCancel: typeof axios.isCancel
}

const getBearerToken = (token: string) => {
  return token ? 'Bearer ' + token : ''
}

const api = axios.create({
  baseURL: conf.API_BASE_URL
}) as APIInstance

api.CancelToken = axios.CancelToken
api.isCancel = axios.isCancel
api.interceptors.request.use(
  function (config: any) {
    config.headers['Accept-Language'] = getLocale()
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const protectedAPI = axios.create({
  baseURL: conf.API_BASE_URL
}) as APIInstance

protectedAPI.CancelToken = axios.CancelToken
protectedAPI.isCancel = axios.isCancel
protectedAPI.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    if (config.headers === undefined) {
      config.headers = {}
    }
    let token = getToken()
    config.headers['Accept-Language'] = getLocale()
    config.headers['Authorization'] = getBearerToken(token)
    if (getCompany()) {
      config.headers['X-Company-ID'] = getCompany()
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
protectedAPI.interceptors.response.use(
  (res) => res,
  (error) => {
    setTimeout(() => {
      import('app/store')
        .then((mod) => mod.default)
        .then((store) => {
          if (error?.response) {
            const { status, data } = error.response
            store.dispatch(setStatus({ data, status }))
            const dateNow =
              Math.floor(Date.now() / 1000) + COOLDOWN_RATE_LIMIT_IN_SECOND
            store.dispatch(setTimestamp(dateNow))
            if (status === 401) store.dispatch({ type: doLogout.rejected.type })
          }
        })
    }, 50)

    return Promise.reject(error)
  }
)

const stringify = async (payload: any) => {
  const stringifyModule = await import('query-string').then(
    (mod) => mod.stringify
  )
  return stringifyModule(payload)
}

const responseToDownload = (response: any) => {
  const file = {
    type: response.headers['content-type'],
    name: response.headers['content-disposition'].split('filename=')[1]
  }
  return {
    file,
    data: response.data
  }
}

const loginAPI = async (payload: any) => {
  const response = await api.post(`/gowork/auth/login`, payload)
  return response.data
}

const getEmployeeBenefitAPI = async (payload: any) => {
  const { employee_id } = payload
  const response = await protectedAPI.get(
    `/gowork/v1/employee/${employee_id}/benefit`
  )
  return response.data
}

const getPolicyAPI = async (payload: any) => {
  const query =
    Object.keys(payload).length > 0 ? '?' + (await stringify(payload)) : ''
  const response = await protectedAPI.get(`/gowork/v1/policy${query}`)
  return response.data
}

const createPolicyAPI = async (payload: any) => {
  const response = await protectedAPI.post('/gowork/v1/policy', payload)
  return response.data
}

const saveCCCompanyAPI = async (payload: any) => {
  const response = await protectedAPI.post('/gowork/v1/company/cc', payload)
  return response.data
}

const deleteCCCompanyAPI = async () => {
  const response = await protectedAPI.delete('/gowork/v1/company/cc')
  return response.data
}

const retryPaymentBookingAPI = async () => {
  const response = await protectedAPI.post('/gowork/v1/booking-payments/retry')
  return response.data
}

const updatePolicyAPI = async (payload: any) => {
  const { id } = payload
  const response = await protectedAPI.patch(`/gowork/v1/policy/${id}`, payload)
  return response.data
}

const deletePolicyAPI = async (payload: any) => {
  const response = await protectedAPI.delete('/gowork/v1/policy', {
    data: payload
  })
  return response.data
}

const getOverviewAPI = async () => {
  const response = await protectedAPI.get(`/gowork/v1/dashboard`)
  return response.data
}

const updateOnboardingPreviewAPI = async () => {
  const response = await protectedAPI.patch(
    `/gowork/v1/user/onboarding-preview`
  )
  return response.data
}

const getTripsAPI = async (payload: any) => {
  let query =
    Object.keys(payload).length > 0 ? '?' + (await stringify(payload)) : ''
  const response = await protectedAPI.get(`/gowork/booking/list${query}`)
  return response.data
}

const getTripsDownloadAPI = async (payload: any) => {
  let query =
    Object.keys(payload).length > 0 ? '?' + (await stringify(payload)) : ''
  const response = await protectedAPI.get(`/gowork/v1/booking/download${query}`)
  return responseToDownload(response)
}

const getTripDetailAPI = async (payload: any) => {
  const { order_number } = payload
  const response = await protectedAPI.get(
    `/gowork/booking/details/${order_number}`
  )
  return response.data
}

const createVoucherAPI = async (payload: any) => {
  const response = await protectedAPI.post('/gowork/v1/voucher', payload)
  return response.data
}

const getVouchersAPI = async (payload: any) => {
  let data = { limit: 10, ...payload }
  const query =
    Object.keys(data).length > 0 ? '?' + (await stringify(data)) : ''
  const response = await protectedAPI.get(`/gowork/v1/voucher${query}`)
  return response.data
}

const toggleVoucherEnabledAPI = async (payload: any) => {
  const { enabled, id } = payload
  const response = await protectedAPI.put(`/gowork/v1/voucher/${id}`, {
    enabled
  })
  return response.data
}

const downloadVoucherAPI = async (payload: any) => {
  const response = await protectedAPI.get(
    `/gowork/v1/voucher/download/${payload.id}`
  )
  return responseToDownload(response)
}

const getEmployeesAPI = async (payload: any) => {
  let data = { limit: 10, ...payload }
  const query =
    Object.keys(data).length > 0 ? '?' + (await stringify(data)) : ''

  const response = await protectedAPI.get(`/gowork/employee/list${query}`)
  return response.data
}

const createEmployeeAPI = async (payload: any) => {
  const response = await protectedAPI.post('/gowork/employee/create', payload)
  return response.data
}

const updateEmployeeAPI = async (payload: any) => {
  const response = await protectedAPI.put('/gowork/employee/update', payload)
  return response.data
}

const bulkUpdateGroupIdEmployeeAPI = async (payload: any) => {
  const response = await protectedAPI.put(
    '/gowork/employee/update-group',
    payload
  )
  return response.data
}

const deleteEmployeeAPI = async (payload: any) => {
  const { all, ids } = payload
  const transformedPayload = {
    data: {
      ids: all ? [] : ids
    }
  }
  const response = await protectedAPI.delete(
    '/gowork/employee/delete',
    transformedPayload
  )
  return response.data
}

const resendEmployeeActivationAPI = async (payload: any) => {
  const { all, employee_ids } = payload
  const transformedPayload = {
    employee_ids: all ? [] : employee_ids
  }
  const response = await protectedAPI.post(
    '/gowork/employee/resend',
    transformedPayload
  )
  return response.data
}

const getGroupsAPI = async () => {
  const response = await protectedAPI.get('/gowork/group')
  return response.data
}

const createGroupAPI = async (payload: any) => {
  const response = await protectedAPI.post('/gowork/group/create', payload)
  return response.data
}

const updateGroupAPI = async (payload: any) => {
  const response = await protectedAPI.put('/gowork/group/update', payload)
  return response.data
}

const deleteGroupAPI = async (payload: any) => {
  const response = await protectedAPI.delete('/gowork/group/delete', {
    data: payload
  })
  return response.data
}

const verifyToken = async (token: any) => {
  const config = {
    headers: {
      Authorization: getBearerToken(token)
    }
  }
  const response = await api.get('/gowork/v1/auth/verify-email', config)
  return response.data
}

const verifyResetPasswordTokenAPI = async (token: string) => {
  const config = {
    headers: {
      Authorization: getBearerToken(token)
    }
  }
  const response = await api.get(
    '/gowork/v1/auth/verify-reset-password',
    config
  )
  return response.data
}

const verifyEmployeeTokenAPI = async (token: string) => {
  const config = {
    headers: {
      Authorization: getBearerToken(token)
    }
  }
  const response = await api.get('/gowork/v1/employee/verify', config)
  return response.data
}

const verifyIndividualEmployeeTokenAPI = async (token: string) => {
  const config = {
    headers: {
      Authorization: getBearerToken(token)
    }
  }
  const response = await api.post(
    '/gowork/v1/employee/individual/email-verification/verify',
    undefined,
    config
  )
  return response.data
}

const resendEmployeeVerificationTokenAPI = async (payload: any) => {
  const response = await api.post(
    '/gowork/v1/employee/resend-verification',
    payload
  )
  return response.data
}

const logoutAPI = async () => {
  const response = await protectedAPI.post('/gowork/auth/logout')
  return response.data
}

const setPasswordUser = async ({
  password,
  token
}: {
  password: string
  token: string
}) => {
  const config = {
    headers: {
      Authorization: getBearerToken(token)
    }
  }
  const response = await api.put(
    '/gowork/auth/set-password',
    { password },
    config
  )
  return response.data
}

const setResetPasswordUserAPI = async (payload: any, token: string) => {
  const config = {
    headers: {
      Authorization: getBearerToken(token)
    }
  }
  const response = await api.post(
    '/gowork/v1/auth/reset-password',
    payload,
    config
  )
  return response.data
}

const sendForgotPasswordAPI = async (payload: any) => {
  const response = await api.post('/gowork/v1/auth/forgot-password', payload)
  return response.data
}

const getSFTPDetailAPI = async () => {
  const response = await protectedAPI.get(`/gowork/v1/sftp/user`)
  return response.data
}

const createSFTPDetailAPI = async (payload: any) => {
  const response = await protectedAPI.post(`/gowork/v1/sftp/user`, payload)
  return response.data
}

const updateSFTPDetailAPI = async (payload: any) => {
  const response = await protectedAPI.patch(`/gowork/v1/sftp/user`, payload)
  return response.data
}

type DownloadNotificationPayload = {
  fullPath: string
  id: string
}

const downloadNotificationAPI = async (
  payload: DownloadNotificationPayload
) => {
  const { fullPath, id } = payload
  const path = fullPath ? fullPath : `/gowork/v1/notifications/download/${id}`
  const response = await protectedAPI.get(path)
  return responseToDownload(response)
}

const getServiceTypeAPI = async (payload: any) => {
  const { countryCode, ...restPayload } = payload
  const query =
    Object.keys(restPayload).length > 0
      ? '?' + (await stringify(restPayload))
      : ''
  const response = await protectedAPI.get(
    `/gowork/v1/country/${countryCode.toLowerCase()}/service-types${query}`
  )
  return response.data
}

const getNotificationListAPI = async (payload: any) => {
  const query =
    Object.keys(payload).length > 0 ? '?' + (await stringify(payload)) : ''
  const response = await protectedAPI.get(`/gowork/v1/notifications${query}`)
  return response.data
}

const getNotificationCountAPI = async () => {
  const response = await protectedAPI.get('/gowork/v1/notifications/count')
  return response.data
}

const markAsReadNotificationAPI = async (payload: any) => {
  const response = await protectedAPI.post('/gowork/v1/notifications', payload)
  return response.data
}

const getUserProfileAPI = async () => {
  const response = await protectedAPI.get('/gowork/v1/user', {
    headers: {
      'X-Company-ID': ''
    }
  })
  return response.data
}

const getUserPermissionsAPI = async () => {
  const response = await protectedAPI.get('/gowork/v1/user/permissions')
  return response.data
}

const getCompanyDetailAPI = async () => {
  const response = await protectedAPI.get('/gowork/v1/company')
  return response.data
}

const uploadCompanyLogoAPI = async (imageData: FormData, options: any) => {
  const response = await protectedAPI.post(
    '/gowork/v1/company/logo',
    imageData,
    options
  )
  return response.data
}

const setPaymentMethodLabelAPI = async (payload: any) => {
  const response = await protectedAPI.patch(
    '/gowork/v1/company/payment-method-label',
    payload
  )
  return response.data
}

const getTripConfigAPI = async () => {
  const response = await protectedAPI.get('/gowork/v1/company/trip-config')
  return response.data
}

const setTripConfigAPI = async (payload: any) => {
  const response = await protectedAPI.patch(
    '/gowork/v1/company/trip-config',
    payload
  )
  return response.data
}

const getHelpCenterListAPI = async () => {
  const response = await axios.get('/data/help-center.json')
  return response.data
}

export {
  api,
  protectedAPI,
  loginAPI,
  verifyToken,
  verifyResetPasswordTokenAPI,
  verifyEmployeeTokenAPI,
  verifyIndividualEmployeeTokenAPI,
  setPasswordUser,
  setResetPasswordUserAPI,
  setTripConfigAPI,
  setPaymentMethodLabelAPI,
  sendForgotPasswordAPI,
  resendEmployeeActivationAPI,
  saveCCCompanyAPI,
  resendEmployeeVerificationTokenAPI,
  retryPaymentBookingAPI,
  toggleVoucherEnabledAPI,
  updateOnboardingPreviewAPI,
  markAsReadNotificationAPI,
  createVoucherAPI,
  createPolicyAPI,
  createGroupAPI,
  createEmployeeAPI,
  createSFTPDetailAPI,
  getEmployeeBenefitAPI,
  getPolicyAPI,
  getVouchersAPI,
  getOverviewAPI,
  getEmployeesAPI,
  getGroupsAPI,
  getTripsAPI,
  getTripsDownloadAPI,
  getNotificationListAPI,
  getNotificationCountAPI,
  getServiceTypeAPI,
  getUserProfileAPI,
  getUserPermissionsAPI,
  getCompanyDetailAPI,
  getTripConfigAPI,
  getHelpCenterListAPI,
  getSFTPDetailAPI,
  getTripDetailAPI,
  downloadVoucherAPI,
  downloadNotificationAPI,
  updateSFTPDetailAPI,
  updateGroupAPI,
  updateEmployeeAPI,
  updatePolicyAPI,
  bulkUpdateGroupIdEmployeeAPI,
  uploadCompanyLogoAPI,
  deleteGroupAPI,
  deleteEmployeeAPI,
  deletePolicyAPI,
  deleteCCCompanyAPI,
  logoutAPI
}
